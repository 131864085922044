import { useState, useCallback } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText'
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import PhoneIphone from '@material-ui/icons/PhoneIphone'
import Email from '@material-ui/icons/Email';
import SMS from '@material-ui/icons/Sms';

import React from 'react';
import { OptionModel } from './slices';

export interface MFAOptionsProps {
  message: string;
  options: OptionModel[] | null;
  selectedIndex: number;
  onChange: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nextStep: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    fontSize: '14px',
    backgroundColor: '#F3F4F5',
    borderRadius: '2px',
    marginRight: '10px',
    marginLeft: '10px',
    padding: '10px 16px 10px 16px'
  },
  icon: {
    fontSize: '60px'
  },
  selectedIcon: {
    fontSize: '60px',
    color: theme.palette.primary.light
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',

  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  otherMfa: {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  miauthTitle: {
    textAlign: 'center',
    fontSize: '14px',
    marginBottom: theme.spacing(0.5),
  },
  title: {
    textAlign: 'center',
    fontSize: '14px',
    marginBottom: theme.spacing(2),
  }
}));

const iconMap: any = {
  hypr_push: PhoneIphone,
  sms_otp: SMS,
  email_otp: Email,
  authenticator: PhoneIphone,
}

const nameMap: any = {
  hypr_push: 'miAuth',
  sms_otp: 'SMS',
  email_otp: 'Email',
  authenticator: 'Authenticator'
}

export const MFAOptions = (props: any) => {
  const { message, options, selectedIndex, onChange } = props;
  const [showOtherOptions, setOtherOptions] = useState(false);
  const classes = useStyles();

  const optionKeys = options?.map((option: OptionModel) => option.key) || [];
  const hasMiAuth = optionKeys.includes('hypr_push');
  const onlyMiAuth =  hasMiAuth && optionKeys.length === 1;

  const handleOther = useCallback(() => {
    setOtherOptions(true);
  }, [showOtherOptions]);


  if (onlyMiAuth || hasMiAuth && !showOtherOptions) {
    return (
      <div className={classes.container}>
        <DialogContentText id="alert-dialog-description" className={classes.miauthTitle}>Please confirm your identity with miAuth</DialogContentText>
        <DialogContentText id="alert-option-description" className={classes.nextStep}>
          {options[selectedIndex].nextStep}
        </DialogContentText>
        {!onlyMiAuth && <div id="other-mfa-options" onClick={handleOther} className={classes.otherMfa}>Other MFA Options</div>}
      </div>
    )
  }

  if (showOtherOptions || !hasMiAuth) {
    return (
      <div className={classes.container}>
      <DialogContentText id="alert-dialog-description" className={classes.title}>Please select an MFA option to confirm your identity</DialogContentText>
      <div className={classes.optionContainer}>
        {options.map((option: OptionModel, index: number) => {
          const Icon = iconMap[option.key];
          const selected = selectedIndex === index;
          const name = nameMap[option.key];
          const className = selected ? classes.selectedIcon : classes.icon;

          return (
            <div id={option.key} key={option.key} className={classes.button} onClick={() => onChange(option.key)}>
              <Icon className={className} />
              <div>{name}</div>
            </div>
          )
        })}
      </div>
      <DialogContentText id="alert-option-description" className={classes.nextStep}>
        {options[selectedIndex].nextStep}
      </DialogContentText>
    </div>
    )
  }

  return null;
}