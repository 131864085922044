import {
  call,
  put,
  takeLatest,
  select,
  getContext,
  ForkEffect,
  delay,
} from "redux-saga/effects";
import { multiFactorSlice } from "./slices";
import { IState } from "store/reducers";
import { PUBLIC_URL } from "env";
import { appSlice } from "components/App/slice";
import { HttpError } from "components/NetworkError";
import validator from "validator";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const debug = require("debug")("eog-oidc-app:multifactor:sagas");

const types: { [key: string]: any } = {
  authenticator: function*(interactionId: string) {
    const history = yield getContext("history");
    const historyUrl = `/v2/interaction/${interactionId}/mfa/totp`;
    history.push(historyUrl);
  },
  hypr_push: function*(interactionId: string) {
    const history = yield getContext("history");
    const historyUrl = `/v2/interaction/${interactionId}/mfa/hypr_push`;
    history.push(historyUrl);

    const pushUrl = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/mfa/v2/hypr_push`;
    yield call(fetch, pushUrl, { method: "POST" });

    // if (!response.ok) {
    //   const location = response.headers.get("Location");

    //   if (location) {
    //     window.location.href = encodeURI(location);
    //   }
    // }

    // if (response.ok) {
    //   const location = response.headers.get("Location");
    //   if (location) {
    //     window.location.href = encodeURI(location);
    //     return;
    //   }
    //   yield put(multiFactorSlice.actions.pressNextStepSucceeded());
    // } else {
    //   yield put(
    //     multiFactorSlice.actions.pressNextStepFailed({
    //       message: response.statusText,
    //     })
    //   );
    // }
  },
  duo_push: function*(interactionId: string) {
    /* NAVIGATE TO NEXT SCREEN */
    const history = yield getContext("history");
    const historyUrl = `/iapi/v2/interaction/${interactionId}/mfa/duo_push`;
    history.push(historyUrl);

    /* START PUSH CALL. IT WILL FINISH WHEN THE USER APPROVES IT */
    const pushUrl = `${PUBLIC_URL}/interaction/${interactionId}/mfa/duo_push`;
    const response: Response = yield call(fetch, pushUrl, { method: "POST" });

    if (!response.ok) {
      const location = response.headers.get("Location");

      if (location) {
        window.location.href = encodeURI(location);
      }
    }

    if (response.ok) {
      const location = response.headers.get("Location");
      if (location) {
        window.location.href = encodeURI(location);
        return;
      }
      yield put(multiFactorSlice.actions.pressNextStepSucceeded());
    } else {
      yield put(
        multiFactorSlice.actions.pressNextStepFailed({
          message: response.statusText,
        })
      );
    }

    // yield call(receiveInteraction, response);
  },
  otp: function*(interactionId: string) {
    /* GET OTP KEY */
    const key = yield select((state: IState) => {
      const options = state.multiFactor.form.options;
      if (!options) return null;
      const selectedIndex = state.multiFactor.form.selectedIndex;
      const option = options[selectedIndex] || options[0];
      return option.key;
    });

    /* HAVE SERVER SEND THE OTP CODE */
    const pushUrl = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/mfa/otp`;
    const response: Response = yield call(fetch, pushUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key }),
    });

    if (!response.ok) {
      const location = response.headers.get("Location");

      if (location) {
        window.location.href = encodeURI(location);
      }
    }

    /* IF THE SERVER SENDS THE OTP CODE, THEN NAVIGATE TO THE NEXT SCREEN */
    if (response.ok) {
      yield put(multiFactorSlice.actions.pressNextStepSucceeded());
      const history = yield getContext("history");
      const historyUrl = `/v2/interaction/${interactionId}/mfa/code_entry`;
      history.push(historyUrl);
    } else {
      yield put(
        multiFactorSlice.actions.pressNextStepFailed({
          message: response.statusText,
        })
      );
    }
  },
  magic_link: function*(interactionId: string) {
    /* GET OTP KEY */
    const key = yield select((state: IState) => {
      const options = state.multiFactor.form.options;
      if (!options) return null;
      const selectedIndex = state.multiFactor.form.selectedIndex;
      const option = options[selectedIndex] || options[0];
      return option.key;
    });

    /* HAVE SERVER SEND THE OTP CODE */
    const pushUrl = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/mfa/otp`;
    const response: Response = yield call(fetch, pushUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key }),
    });

    if (!response.ok) {
      const location = response.headers.get("Location");

      if (location) {
        window.location.href = encodeURI(location);
      }
    }

    if (response.ok) {
      /* NAVIGATE TO NEXT SCREEN */
      const history = yield getContext("history");
      const historyUrl = `/v2/interaction/${interactionId}/mfa/magic_link`;
      history.push(historyUrl);
      yield put(multiFactorSlice.actions.pressNextStepSucceeded());
    } else {
      yield put(
        multiFactorSlice.actions.pressNextStepFailed({
          message: response.statusText,
        })
      );
    }
  },
};

function* sendMfaCode() {
  try {
    const interactionId = yield select(
      (state: IState) => state.interaction.interactionId
    );
    const selected = yield select(
      (state: IState) =>
        state.multiFactor.form.options &&
        state.multiFactor.form.options[state.multiFactor.form.selectedIndex]
    );

    const fn = types[selected.type];
    if (fn) {
      yield call(fn, interactionId);
    }
  } catch (error) {
    yield put(appSlice.actions.sagaError({ error }));
  }
}

function* handleShowMultifactor() {
  try {
    const interactionId = yield select(
      (state: IState) => state.interaction.interactionId
    );
    const url = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/mfa/methods`;
    const response = yield call(fetch, url);
    if (response.ok) {
      const json = yield call([response, "json"]);

      const { options, reasonDescription, reason, claims } = json;
      yield put(
        multiFactorSlice.actions.onOptionsReceived({
          options,
          reasonDescription,
          reason,
          claims,
        })
      );
    }
  } catch (error) {
    yield put(multiFactorSlice.actions.getOptionsFailed({ error }));
  }
}

function* handlePressSubmitCode() {
  try {
    const code = yield select((state: IState) => state.multiFactor.otp.code);

    const selected = yield select(
      (state: IState) =>
        state.multiFactor.form.options &&
        state.multiFactor.form.options[state.multiFactor.form.selectedIndex]
    );

    const interactionId = yield select(
      (state: IState) => state.interaction.interactionId
    );

    const otpKind = selected.type === "authenticator" ? "totp" : "otp";

    const url = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/mfa/${otpKind}/verify`;
    const response = yield call(fetch, url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
      }),
    });

    if (!response.ok) {
      const location = response.headers.get("Location");

      if (location) {
        window.location.href = encodeURI(location);
      }
    }

    if (response.ok) {
      const location = response.headers.get("Location");

      if (location) {
        window.location.href = encodeURI(location);
      }

      yield put(multiFactorSlice.actions.pressSubmitCodeOk());
    } else {
      const { status, statusText } = response;
      yield put(
        multiFactorSlice.actions.pressSubmitCodeNotOk({
          status,
          statusText,
        })
      );
    }

    // yield call(receiveInteraction, response);
  } catch (error) {
    yield put(appSlice.actions.sagaError({ error }));
  }
}

function* resetUser() {
  try {
    const interactionId = yield select(
      (state: IState) => state.interaction.interactionId
    );

    const url = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/reset`;

    yield call(fetch, url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    window.location.href = `${PUBLIC_URL}/v2/interaction/${interactionId}`;
  } catch (error) {}
}

function* handlePressCancel() {
  try {
    const interaction = yield select(
      (state: IState) => state.interaction.interaction
    );
    const { cancelUrl } = interaction;
    if (cancelUrl) {
      window.location.href = encodeURI(cancelUrl);
    }
  } catch (error) {
    yield put(appSlice.actions.sagaError({ error }));
  }
}

function* handleWaitForMagicLinkShow(action: any) {
  const interactionId = yield select(
    (state: IState) => state.interaction.interactionId
  );

  /* HAVE SERVER SEND THE OTP CODE */
  let i = 0;
  while (true) {
    const waitUrl = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/wait`;
    const waitResponse: Response = yield call(fetch, waitUrl, {
      method: "POST",
    });
    i++;

    if (!waitResponse.ok) {
      const location = waitResponse.headers.get("Location");

      if (location) {
        window.location.href = encodeURI(location);
      }
    }

    if (waitResponse.ok) {
      const location = waitResponse.headers.get("Location");
      if (location) {
        window.location.href = encodeURI(location);
        return;
      } else {
        const error = yield HttpError.fromResponse(
          `location was not defined`,
          waitResponse
        );
        throw error;
      }
    } else if (waitResponse.status === 408) {
      continue;
    } else {
      const error = yield HttpError.fromResponse(
        `waiting for magic link failed`,
        waitResponse
      );
      debug(error.toString());
      throw error;
    }
  }
}

export function* watchMultiFactorSagas(): Generator<ForkEffect, void, unknown> {
  yield takeLatest(multiFactorSlice.actions.sendMfaCode, sendMfaCode);
  yield takeLatest(multiFactorSlice.actions.resendMfaCode, sendMfaCode);
  yield takeLatest(
    multiFactorSlice.actions.multiFactorShown,
    handleShowMultifactor
  );
  yield takeLatest(
    multiFactorSlice.actions.pressSubmitCode,
    handlePressSubmitCode
  );
  yield takeLatest(multiFactorSlice.actions.pressCancel, handlePressCancel);
  yield takeLatest(
    multiFactorSlice.actions.waitForMagicLinkShown,
    handleWaitForMagicLinkShow
  );
  yield takeLatest(multiFactorSlice.actions.resetUser, resetUser);
}
